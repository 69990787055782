import React from 'react'
import Helmet from 'react-helmet'

export default props => (
  <div>
    <Helmet
      title={`${props.name} | ALAIR`}
      meta={[
        {
          name: 'description',
          content: `${
            props.name
          } at the Office of Institutional Research and Assessment of The University of Alabama`,
        },
        {
          name: 'keywords',
          content: `${
            props.keywords
          }, OIRA, The University of Alabama, AIR, SAIR, ALAIR`,
        },
      ]}
    />
    {props.noHeader ? null : (
      <header>
        <h1>{props.name}</h1>
        <hr />
      </header>
    )}
  </div>
)

// EXAMPLE:
// <Title name="Contact" keywords="foo, bar, baz" />
