import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <PageTitle name="Listserv" />
        <p>
          Join the ALAIR listserv to get the latest news in the Alabama
          institutional research community! No vendors, please!
        </p>
        <form
          action="/listserv/thanks/"
          name="ALAIR LISTSERV REQUEST"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" />
            </label>
          </p>
          <p>
            {' '}
            <select onChange={this.handleChange} name="Title">
              <option defaultValue>Title</option>
              <option value="Dr.">Dr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Mr.">Mr.</option>
              <option value="Miss">Miss</option>
              <option value="Ms.">Ms.</option>
              <option value="Mx.">Mx.</option>
              <option value="Undefined"> </option>
            </select>
          </p>
          <p>
            <input
              size="35"
              type="text"
              name="First Name"
              placeholder="First Name"
              onChange={this.handleChange}
              required
            />
          </p>
          <p>
            <input
              size="35"
              type="text"
              name="Last Name"
              placeholder="Last Name"
              onChange={this.handleChange}
              required
            />
          </p>
          <p>
            <input
              size="35"
              type="text"
              name="Job Title"
              placeholder="Job Title"
              onChange={this.handleChange}
            />
          </p>
          <p>
            <input
              size="35"
              type="text"
              name="Institution"
              placeholder="Institution"
              onChange={this.handleChange}
            />
          </p>
          <p>
            <input
              size="35"
              placeholder="Email"
              type="email"
              name="Email"
              onChange={this.handleChange}
              required
            />
          </p>
          <p>
            <Button type="submit">Send</Button>
          </p>
        </form>
      </Layout>
    )
  }
}
